import * as React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Footer from "../../components/footer"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

export default class JetOil extends React.Component {

  render() {

    return (

      <Layout>

        <Seo title="SPCO" lang="en" />

        <section className="wow animate__fadeIn">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-lg-5 col-md-6 text-center md-margin-30px-bottom wow animate__fadeInLeft">
                <StaticImage
                  src="../../images/s8.PNG"
                  formats={["auto", "webp", "avif"]}
                  alt=""
                  className="border-radius-6 w-100 shadow"
                />
                <StaticImage
                  src="../../images/s9.JPG"
                  formats={["auto", "webp", "avif"]}
                  alt=""
                  className="border-radius-6 w-100 shadow mt-4"
                />
              </div>
              <div className="col-12 col-lg-7 col-md-6 text-center text-md-start padding-eight-lr lg-padding-six-lr md-padding-15px-lr wow animate__fadeInRight" data-wow-delay="0.2s">

                <span className="text-deep-pink alt-font margin-10px-bottom d-inline-block text-medium">Jet fuel</span>

                <p>Jet fuel or aviation turbine fuel (ATF, also abbreviated avtur) is a type of aviation fuel which is used in aircraft powered by gas-turbine engines. It is colorless to straw-colored in appearance. The most commonly used fuels for commercial aviation are Jet A and Jet A-1, which are produced to a standardized international specification. </p>

                <p>Jet fuel is a mixture of a variety of hydrocarbons. Since the exact composition of jet fuel varies widely based on petroleum source, it is not feasible to define jet fuel as a ratio of specific hydrocarbons. Indeed, jet fuel is defined as a performance specification rather than a chemical compound. Furthermore, the range of molecular mass between hydrocarbons (or different carbon numbers) is determined by the requirements for the product, such as the freezing point or smoke point. Kerosene-type jet fuel (including Jet A and Jet A-1, JP-5, and JP-8) has a carbon number distribution between about 8 and 16 (carbon atoms per molecule); wide-cut or naphtha-type jet fuel (including Jet B and JP-4), between about 5 and 15.</p>


                <a className="btn btn-primary" href="/files/Jet-fuel.pdf">Download</a>

              </div>
            </div>
          </div>
        </section>

        <Footer />

      </Layout>

    )

  }

}